import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./IF_Swiper.css";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import App from "./App";

const theme = extendTheme({
    colors: {
        pink: "#EA2A5E",
        // 234, 42, 94
        ungu: "#6A106B",
        biruMuda: "#0BB4C0",
        biruTua: "#3A108C",
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ChakraProvider theme={theme}>
            <App />
        </ChakraProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
