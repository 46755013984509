import { Button, Heading } from "@chakra-ui/react";
export default function IF_Button({ text }) {
    return (
        <Button
            zIndex={2}
            size="lg"
            rounded="full"
            color="white"
            transition="all .3s"
            pos="relative"
            bgGradient="linear(to-bl, pink 65%  ,ungu)"
            _hover={{
                backgroundClip: "text",
                transform: "scale(1.3)",
            }}
        >
            <Heading fontSize="lg">{text}</Heading>
        </Button>
    );
}
