import {
    Center,
    Circle,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    HStack,
    Heading,
    Icon,
    useDisclosure,
} from "@chakra-ui/react";
import navigation from "../../data/navigation.json";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

function Phone_Drawer({ onClose, isOpen, router }) {
    return (
        <Drawer onClose={onClose} isOpen={isOpen} placement="right">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton color="white" />
                <DrawerBody pos="relative" bgColor="gray.800">
                    {navigation.map((n, index) => {
                        return (
                            <Link
                                key={n.path}
                                to={n.path}
                                onClick={() => onClose()}
                            >
                                <Heading
                                    as="h3"
                                    {...(router.pathname === n.path
                                        ? {
                                              fontSize: "2xl",
                                              color: "pink",
                                          }
                                        : {
                                              fontSize: "md",
                                              color: "white",
                                          })}
                                    py={3}
                                >
                                    <motion.div
                                        transition={{
                                            duration: 0.1,
                                            delay: index * 0.1,
                                        }}
                                        initial={{
                                            x: "-100%",
                                        }}
                                        whileInView={{
                                            x: "0%",
                                        }}
                                    >
                                        {n.text}
                                    </motion.div>
                                </Heading>
                            </Link>
                        );
                    })}
                    <Link
                        style={{
                            position: "absolute",
                            bottom: 10,
                            left: "50%",
                            display: "flex",
                            justifyContent: "center",
                            transform: "translateX(-50%)",
                        }}
                        onClick={() => onClose()}
                        to="/"
                    >
                        <motion.div
                            style={{ width: "fit-content" }}
                            initial={{
                                scale: 0,
                            }}
                            animate={{
                                scale: 1,
                            }}
                            transition={{
                                duration: 0.5,
                                delay: navigation.length * 0.1,
                            }}
                        >
                            <Circle w="100%" bgColor="white" p={6}>
                                <img alt="" src="/logo-crop.png"></img>
                            </Circle>
                        </motion.div>
                    </Link>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}

export default function Header({ nav = [] }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const router = useLocation();

    return (
        <Center
            m="0 auto"
            w="90%"
            py={4}
            pos="absolute"
            top="0px"
            left="5%"
            zIndex={2}
        >
            {/* collpase (Phone view) start */}
            <HStack
                w="100%"
                justifyContent="space-between"
                display={{ base: "flex", "2xl": "none" }}
            >
                {/* logo / image / icon */}
                <Flex
                    id="logo_wrapper"
                    justifyContent="center"
                    alignItems="center"
                    h={16}
                >
                    <Circle bgColor="white" p={1}>
                        <img
                            alt=""
                            style={{
                                width: "40px",
                                height: "40px",
                            }}
                            src="/logo_icon.png"
                        />
                    </Circle>
                </Flex>

                {/* hamburger */}
                <Icon onClick={onOpen} fontSize="2xl" as={GiHamburgerMenu} />
                <Phone_Drawer
                    nav={nav}
                    router={router}
                    isOpen={isOpen}
                    onClose={onClose}
                />
            </HStack>
            {/* collpase (Phone view) end */}

            {/* no collapse view start till end of code */}
            <HStack
                display={{ base: "none", "2xl": "flex" }}
                justifyContent="space-between"
                w="100%"
            >
                <Flex id="logo_wrapper" alignItems="center" h={20} minW="140px">
                    <motion.div
                        animate={{
                            rotate: [0, 360, 720],
                            opacity: [0.5, 1],
                            scale: [0, 1],
                        }}
                        transition={{
                            duration: 1,
                            delay: 1 + navigation.length * 0.3,
                        }}
                    >
                        <Circle zIndex={2} bgColor="white" p={2} w={20} h={20}>
                            <img alt="" src="/logo_icon.png" />
                        </Circle>
                    </motion.div>
                </Flex>
                <HStack gap={6}>
                    {navigation.map((n, index) => {
                        return (
                            <Link key={n.path} to={n.path}>
                                <motion.div
                                    animate={{
                                        opacity: [0, 1],
                                        y: ["-100%", "0%"],
                                    }}
                                    transition={{
                                        duration: 0.3,
                                        delay: 0.3 * index + 1,
                                    }}
                                >
                                    <Heading
                                        whiteSpace="nowrap"
                                        cursor="pointer"
                                        transition="all 0.3s"
                                        _hover={{
                                            transform: "scale(1.1)",
                                            color: "pink",
                                        }}
                                        {...(router.pathname === n.path
                                            ? {
                                                  transform: "scale(1.2)",
                                                  color: "pink",
                                              }
                                            : {
                                                  transform: "scale(1)",
                                                  color: "white",
                                              })}
                                        as="h3"
                                        fontSize={"lg"}
                                    >
                                        {n.text}
                                    </Heading>
                                </motion.div>
                            </Link>
                        );
                    })}
                </HStack>
            </HStack>
        </Center>
    );
}
