import { Center, Heading } from "@chakra-ui/react";
import { EffectCoverflow, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import FadeIn from "./Animation/FadeIn";

function Foto({ data }) {
    return (
        <img
            src={process.env.REACT_APP_IMAGE_URL + data.filename}
            width={400}
            height={400}
            alt=""
            loading="lazy"
            style={{ objectFit: "contain" }}
        />
    );
}

function Film({ data }) {
    return (
        <video preload="metadata" controls>
            <source
                src={`${process.env.REACT_APP_IMAGE_URL + data.filename}#t=1`}
                type="video/mp4"
            />
            Your browser does not support the video tag.
        </video>
    );
}

export default function IF_Swiper({ data }) {
    return (
        <Center
            zIndex={5}
            w="100%"
            bg="url(/images/bg.jpg) bottom no-repeat"
            bgSize="cover"
            flexDir="column"
            py={8}
            mt="0px !important"
        >
            <FadeIn>
                <Heading textAlign="center" color="pink">
                    ALBUM
                </Heading>
            </FadeIn>

            <FadeIn init_y="50%" amount={0}>
                <Center w="100%">
                    <Center w={{ base: "70%", xl: "50%" }}>
                        <Swiper
                            effect={"coverflow"}
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView="1"
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }}
                            navigation={true}
                            pagination={true}
                            modules={[EffectCoverflow, Pagination, Navigation]}
                            style={{
                                padding: "10vh 10vw",
                            }}
                        >
                            {data.map((d, i) => (
                                <SwiperSlide key={i}>
                                    <Center
                                        h={{ base: "50vw", xl: "30vw" }}
                                        w={{ base: "50vw", xl: "30vw" }}
                                    >
                                        {d.type === "image" ? (
                                            <Foto data={d} />
                                        ) : (
                                            <Film data={d} />
                                        )}
                                    </Center>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Center>
                </Center>
            </FadeIn>
        </Center>
    );
}
