import FadeIn from "../components/Animation/FadeIn";
import IF_Swiper from "../components/IF_Swiper";
import { Center, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { getManagement } from "../api/mangement.js";

const data = [
    {
        title: "PT BANK MUAMALAT INDONESIA TBK (BMI)  ",
        src: "/aset/partner1.jpeg",
        background: "#650072",
    },
    {
        title: "PT WASKITA KARYA (PERSERO) ",
        src: "/aset/partner2.png",
        background: "white",
    },
    {
        title: "STAMFORD ACTIVITY CLUB ",
        src: "/aset/partner3.png",
        background: "white",
    },
    {
        title: "PT EVERBESINDO SURYA JAYA",
        src: "/aset/partner4.png",
        background: "white",
    },
    {
        title: "PT RIMAU MULTI PUTRA PRATAMA TBK (CMPP)",
        src: "/aset/partner5.png",
        background: "white",
    },
    {
        title: "PT RIMAU BAHTERA SHIPPING",
        src: "/aset/partner6.png",
        background: "white",
    },
    {
        title: "PT PELAYARAN BAHTERA JAYA",
        src: "/aset/partner7.png",
        background: "white",
    },
    {
        title: "PT RIMAU SHIPPING",
        src: "/aset/partner8.png",
        background: "white",
    },
    {
        title: "PT -",
        src: "/aset/partner9.png",
        background: "white",
    },
    {
        title: "PT TRI SUKSES WANATAMA",
        src: "/aset/partner10.png",
        background: "white",
    },
];

export default function Gymmanagement() {
    const in_view_ref = useRef();
    const isInView = useInView(in_view_ref, { once: true, amount: 0.8 });
    const [managementFile, set_managementFile] = useState([]);
    useEffect(() => {
        getManagement().then((res) => {
            set_managementFile(res);
        });
    }, []);

    return (
        <>
            <Center flexDir="column" pt={{ base: "10vh", xl: "20vh" }}>
                <Stack
                    w="80vw"
                    ref={in_view_ref}
                    pos="relative"
                    _before={{
                        pos: "absolute",
                        content: "''",
                        width: "100%",
                        height: "100%",
                        bgColor: "gray.900",
                        transition: "all 1s ease-out",
                        right: isInView ? "-100%" : "0%",
                    }}
                >
                    <Heading color="pink">I-FIT GYM MANAGEMENT</Heading>
                    <Text fontSize="xl">
                        I-FIT indonesia membantu anda untuk mengelola
                        operasional dan fasilitas gym. Mulai dari pengadaan
                        kelas dan program personal training. Manfaat dari gym
                        management adalah dapat mengatur kesehatan dan kebugaran
                        para karyawan dengan melakukan pengecekan komposisi
                        tubuh secara terukur dan rutin.
                    </Text>
                </Stack>
            </Center>

            <FadeIn>
                <Stack mx="auto" my={10} w="80vw">
                    <Heading color="pink">OUR CLIENTS</Heading>
                    <SimpleGrid
                        columns={{
                            base: 1,
                            md: 3,
                            lg: 5,
                        }}
                        gap={4}
                    >
                        {data.map((d) => (
                            <Stack
                                _hover={{
                                    transform: "translateY(-5px)",
                                }}
                                transition="all .3s ease-in-out"
                                key={d.src}
                            >
                                <Center
                                    border={`3px solid ${d.background}`}
                                    h={{ base: "10vh", lg: "20vh" }}
                                    bgColor={d.background}
                                >
                                    <img
                                        style={{
                                            objectFit: "contain",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                        src={d.src}
                                        alt=""
                                    />
                                </Center>
                                <Text textAlign="center">{d.title}</Text>
                            </Stack>
                        ))}
                    </SimpleGrid>
                </Stack>
            </FadeIn>

            <IF_Swiper data={managementFile} />
        </>
    );
}
