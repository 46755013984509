import axios from "axios";

export async function getTeam() {
    return await axios
        .get(`${process.env.REACT_APP_API_URL}/getTeam.php`)
        .then((res) => res.data)
        .catch((err) => {
            console.log(err);
            return [];
        });
}
