import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages";
import Certification from "./pages/certification";
import Contact from "./pages/contact";
import Gallery from "./pages/gallery";
import Gymmanagement from "./pages/gymmanagement";
import Trainer from "./pages/personaltrainer";
import Postural from "./pages/postural";
import Team from "./pages/team";
import Main from "./templates/main";

function App() {
    const social_media = [
        {
            icon: "/icon/instagram.png",
            text: "Instagram",
            link: "https://www.instagram.com/i_fit.indonesia/",
        },
        {
            icon: "/icon/tik-tok.png",
            text: "Tik Tok",
            link: "https://www.tiktok.com/@i_fit.indonesia/",
        },
        {
            icon: "/icon/facebook.png",
            text: "Facebook",
            link: "https://www.facebook.com/ifit.dbi",
        },
        {
            icon: "/icon/youtube.png",
            text: "Youtube",
            link: "https://www.youtube.com/channel/UCiuLrnLrsx02LBb7VpRCzZw",
        },
        {
            icon: "/icon/whatsapp.png",
            text: "Whatsapp",
            link: "https://wa.me/6282125476557",
        },
        {
            icon: "/icon/gmail.png",
            text: "E-mail",
            link: "mailto:kinan.credomanagement@gmail.com",
        },
    ];

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main social_media={social_media} />}>
                    <Route index element={<Home />} />
                    <Route path="postural" element={<Postural />} />
                    <Route path="personaltrainer" element={<Trainer />} />
                    <Route path="gymmanagement" element={<Gymmanagement />} />
                    <Route path="certification" element={<Certification />} />
                    <Route path="team" element={<Team />} />
                    <Route path="contact" element={<Contact social_media={social_media} />} />
                    <Route path="gallery" element={<Gallery />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
