import {
    AspectRatio,
    Box,
    Center,
    Collapse,
    Flex,
    Heading,
    Stack,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { motion } from "framer-motion";
import { getCertification } from "../api/certification.js";
import { useEffect, useState } from "react";
import FadeIn from "../components/Animation/FadeIn";

const packages = [
    {
        title: "Bootcamp 1",
        desc: "Training intensif 12 jam mengenai dasar dasar pelatihan bootcamp untuk format kelas grup. Mempelajari mengenai filosofi, manajemen kelas dan membuat program latihan. Dengan mengikuti training ini, peserta akan mampu untuk memimpin kelas grup bootcamp secara sistematis.",
    },
    {
        title: "Bootcamp 2",
        desc: "Training intensif 12 jam mengenai  pelatihan bootcamp tingkat mahir. Mempelajari metode latihan dan penggunaan alat yg variatif dan inovatif dan juga Mempelajari bagaimana membuat suatu metode test kebugaran untuk peserta. Dengan mengikuti training ini peserta akan mampu untuk membuat program kelas bootcamp yg menarik dan lbh menantang.",
    },
    {
        title: "Kettlebell and resistance band training",
        desc: "Workshop singkat tentang bagaimana menggunakan kettlebell dan resistance band sebagai alat bantu latihan sederhana yg mudah, serbaguna dan dapat dilakukan dimana saja. Dengan mengikuti kursus ini, peserta akan mendapatkan pengetahuan berguna yang bisa  digunakan untuk melatih klien secara individu dan group training.",
    },
    {
        title: "Resistance training program design",
        desc: "Workshop singkat mengenai program desain untuk resistance training. Dengan mengikuti workshop ini peserta akan belajar bagaimana cara mendesain program resistance training  yg tepat dan terukur meliputi FITT, SAID principle, dan progresi overload. Setelah mengikuti workshop ini, peserta akan mendapatkan pengetahuan dalam hal resistance training program desain untuk diaplikasikan kepada klien maupun diri sendiri.",
    },
];

function CollapseEx({ children, title }) {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Center
            onClick={onToggle}
            transition="all .3s"
            pos="relative"
            width="45%"
            role="group"
            h="fit-content"
        >
            <Box
                _groupHover={{
                    bgColor: "whiteAlpha.800",
                }}
                zIndex={0}
                rounded="xl"
                pos="absolute"
                w="full"
                h="full"
            ></Box>
            <Stack
                _groupHover={{
                    transform: "translate(-5px, -5px)",
                }}
                transition="all .3s"
                bgColor={isOpen ? "#AB244C" : ""}
                rounded="xl"
                w="full"
                zIndex={1}
                h="full"
            >
                <Center>
                    <FadeIn>
                        <Center
                            minH="100px"
                            bgColor="pink"
                            rounded="xl"
                            p={{ base: 1, md: 2 }}
                        >
                            <Heading
                                textAlign="center"
                                fontSize={{ base: "lg", md: "2xl" }}
                            >
                                {title}
                            </Heading>
                        </Center>
                    </FadeIn>
                </Center>
                <Collapse in={isOpen} animateOpacity>
                    <Box pb={4} px={4}>
                        {children}
                    </Box>
                </Collapse>
            </Stack>
        </Center>
    );
}

function Local_carousel({ data }) {
    return (
        <Center pt={{ base: "10vh", xl: "20vh" }} w="90vw">
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
            >
                {data.map((i) => (
                    <SwiperSlide key={i.src}>
                        <Center marginX="auto">
                            <AspectRatio
                                w={{ base: "90vw", lg: "70vw" }}
                                ratio={3 / 1}
                            >
                                <motion.div
                                    initial={{
                                        opacity: 0,
                                    }}
                                    whileInView={{
                                        opacity: 1,
                                    }}
                                    transition={{
                                        duration: 1,
                                    }}
                                    style={{
                                        background: `url(${
                                            process.env.REACT_APP_IMAGE_URL +
                                            i.filename
                                        }) no-repeat center`,
                                        backgroundSize: "cover",
                                        borderRadius: "10px",
                                    }}
                                />
                            </AspectRatio>
                        </Center>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Center>
    );
}

export default function Certification() {
    const [certificationFile, set_certificationFile] = useState([]);
    useEffect(() => {
        getCertification().then((res) => {
            set_certificationFile(res);
        });
    }, []);

    return (
        <>
            <Center flexDir="column" w="100%" mt={{ base: "12vh", lg: "20vh" }}>
                <Local_carousel data={certificationFile} />
                <Center px={{ base: 4, xl: 0 }} w="100%">
                    <Flex
                        flexWrap="wrap"
                        mx="auto"
                        justifyContent="center"
                        my={8}
                        width={{ base: "100%", xl: "80%" }}
                        gap={{ base: 2, xl: 8 }}
                    >
                        {packages.map((p, index) => (
                            <CollapseEx
                                title={p.title.toUpperCase()}
                                key={index}
                            >
                                <Text
                                    textAlign="center"
                                    fontSize={{ base: "md", md: "xl" }}
                                >
                                    {p.desc}
                                </Text>
                            </CollapseEx>
                        ))}
                    </Flex>
                </Center>
            </Center>
        </>
    );
}
