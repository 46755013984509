import {
    Box,
    Center,
    Collapse,
    Flex,
    Heading,
    Stack,
    Text,
    VStack,
    useDisclosure,
} from "@chakra-ui/react";
import IF_SubMenu from "../components/IF_SubMenu";
import IF_Banner from "../components/IF_Template/IF_banner";
import { motion } from "framer-motion";
import IF_Pricing from "../components/IF_Template/IF_Pricing";
import IF_Swiper from "../components/IF_Swiper";
import FadeIn from "../components/Animation/FadeIn";
import IF_Heading from "../components/IF_Heading";
import { useEffect, useState } from "react";
import { getPostural } from "../api/postural";
import priceList from "../data/price_list";

function CollapseEx({ children, title }) {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack
            onClick={onToggle}
            transition="all .3s"
            bgColor={isOpen ? "rgba(234, 42, 94, .7)" : ""}
            rounded="xl"
            width={{ base: "40%", lg: "30%" }}
        >
            <Center>
                <FadeIn>
                    <Center bgColor="pink" rounded="xl" height="12vh">
                        <Heading textAlign="center" fontSize="2xl">
                            {title}
                        </Heading>
                    </Center>
                </FadeIn>
            </Center>
            <Collapse in={isOpen} animateOpacity>
                <Box pb={4} px={4}>
                    {children}
                </Box>
            </Collapse>
        </Stack>
    );
}

export default function Postural() {
    const [posturalFile, set_posturalFile] = useState([]);
    useEffect(() => {
        getPostural().then((res) => {
            set_posturalFile(res);
        });
    }, []);

    return (
        <>
            <IF_Banner>
                <motion.div
                    animate={{
                        opacity: [0, 0.5, 1],
                        x: ["100%", "-20%", "0%"],
                    }}
                    transition={{ duration: 0.5, delay: 1 }}
                >
                    <Flex
                        flexDir={{ base: "column-reverse", xl: "row" }}
                        gap={{ base: 2, xl: 4 }}
                    >
                        <Box>
                            <IF_SubMenu
                                text1="POSTURAL ASSESSMENT"
                                text2="AND PROGRAMS"
                            />
                            <Text fontSize="xl">
                                Menganalisa postural, kemampuan mobilisasi,
                                stabilisasi dan anatomi tubuh, dan kemudian
                                hasil assessment ini akan menghasilkan suatu
                                design program latihan yang tepat dan terukur
                                sesuai dengan keluhan dan problem postural
                                member. metode yang dilakukan yaitu dengan cara
                                berolahraga yang berfokus kepada penguatan
                                otot-otot inti tubuh(core) dan meningkatkan
                                level fitness client
                            </Text>
                        </Box>
                        <video
                            style={{
                                alignSelf: "center",
                                width: "fit-content",
                                height: "200px",
                            }}
                            controls={true}
                            autoPlay={true}
                            muted={true}
                            src="/images/dokter.mp4"
                        ></video>
                    </Flex>
                </motion.div>
            </IF_Banner>

            <Box role="group" pb={20}>
                <IF_Heading>MASALAH PADA TULANG BELAKANG</IF_Heading>
                <Flex
                    flexWrap="wrap"
                    mx="auto"
                    justifyContent="center"
                    mt={8}
                    width={{ base: "95%", xl: "80%" }}
                    gap={{ base: 4, xl: 8 }}
                >
                    {[
                        {
                            title: "Scoliosis",
                            desc: "kelainan bentuk tulang belakang yang melengkung kesamping berbentuk seperti huruf S atau C secara tidak normal.  Dikarenakan terjadinya kerusakan dibagian tulang belakang, faktor genetika dan faktor tulang belakang yang tidak bertumbuh secara normal pada saat dalam kandungan",
                        },
                        {
                            title: "Lordosis",
                            desc: "kelainan tulang belakang yang membentuk lengkungan bagian lumbar atau pinggang melengkung ke arah dalam secara berlebihan",
                        },
                        {
                            title: "Khyposis",
                            desc: "suatu jenis kelainan tulang belakang yang membuat postur tubuh membungkuk secara tidak normal. ",
                        },
                        {
                            title: "HNP",
                            desc: "kondisi ketika salah satu bantalan atau disc tulang rawan dari tulang belakang menonjol keluar dan menjepit inti saraf. ",
                        },
                        {
                            title: "Lower Back Pain",
                            desc: "rasa nyeri seperti ditusuk atau disetrum listrik pada pinggang atau tulang punggung bagian bawah hingga ke bokong dan kaki yang disertai spasme otot. ",
                        },
                        {
                            title: "Senior Citizen",
                            desc: "kelompok usia yang dimulai dari 55 tahun ke atas yang membutuhkan program  untuk pemadatan tulang dan otot dengan  melatih kekuatan otot, mobilisasi otot, stabilisasi dan core strenghtening yang dibutuhkan sesuai dengan usianya.",
                        },
                        {
                            title: "Frozen Shoulder",
                            desc: "nyeri dan kaku di area bahu yang membuat kesulitan menggerakan sendi bahu sampai ke lengan atas. Penyebabnya dikarenakan adanya jaringan parut yang muncul dan membuat kapsul pelindung menebal sehingga sendi bahu sulit digerakan.",
                        },
                    ].map((item, index) => (
                        <CollapseEx
                            title={item.title.toUpperCase()}
                            key={index}
                        >
                            <Text textAlign="center" fontSize="xl">
                                {item.desc}
                            </Text>
                        </CollapseEx>
                    ))}
                </Flex>
            </Box>

            <Box role="group">
                <IF_Heading>KONSULTASI & ASSESSMENT</IF_Heading>

                <Center pb={12} mb={4}>
                    <Stack
                        w={{ base: "90%", lg: "70%" }}
                        alignItems="center"
                        textAlign="center"
                    >
                        <VStack gap={2}>
                            <FadeIn>
                                <Text fontSize="xl">
                                    Khusus keluhan postural, I-FIT Indonesia
                                    membuka layanan konsultasi dan assessment
                                    dengan Master Coach kami untuk menganalisa
                                    keluhan dan membuat program olahraga yang
                                    cocok dengan Anda.
                                </Text>
                            </FadeIn>
                            <FadeIn>
                                <Text fontSize="xl">
                                    Pada proses assessment ini, client akan
                                    dianalisa kemampuan mobility dan stability
                                    gerak pada sendi dan otot sesuai anatomi
                                    tubuh client.
                                </Text>
                            </FadeIn>
                            <FadeIn>
                                <Text fontSize="xl">
                                    Sebelum melakukan assessment dengan kami,
                                    mohon pastikan Anda sudah melakukan
                                    pengecekan ke Dokter Spesialist Orthopedi
                                    terlebih dahulu untuk mendapatkan hasil
                                    analisa secara medis yang akurat.
                                </Text>
                            </FadeIn>
                        </VStack>
                        <FadeIn>
                            <Center
                                mt={2}
                                mx="auto"
                                w={{ base: "100%", xl: "50vw" }}
                            >
                                <img alt="" src="/aset/pos.png" />
                            </Center>
                        </FadeIn>
                    </Stack>
                </Center>
            </Box>

            <Box role="group">
                <IF_Heading>POSTURAL PROGRAM STUDIO & HOME</IF_Heading>
                <IF_Pricing
                    priceList={priceList.postural_program.home}
                    gradientColor="pink"
                >
                    <VStack gap={2} textAlign="center">
                        <FadeIn>
                            <Text fontSize="xl">
                                I-FIT Indonesia menyediakan program postural di
                                studio dan home. Studio kami memiliki fasilitas
                                equipment olahraga yang akan menunjang program
                                goals client.
                            </Text>
                        </FadeIn>
                        <FadeIn>
                            <Text fontSize="xl">
                                Sedangkan untuk program postural home, personal
                                trainer kami akan membawa beberapa equipment
                                olahraga yang sesuai dengan kebutuhan program
                                client.
                            </Text>
                        </FadeIn>
                    </VStack>
                </IF_Pricing>
            </Box>

            <IF_Swiper data={posturalFile} />
        </>
    );
}
