import { Box, Center, Heading } from "@chakra-ui/react";
import FadeIn from "./Animation/FadeIn";

export default function IF_Heading({ children }) {
    return (
        <FadeIn>
            <Center gap={2} py={4} display="block" w="100%">
                <Box
                    transition="all .3s ease-in"
                    _groupHover={{
                        transform: "translate(-100%)",
                    }}
                    w="100%"
                    borderBlock="1px"
                />
                <Heading
                    bgColor="gray.900"
                    px={[0, 4]}
                    transition="all .3s ease-in"
                    _groupHover={{
                        letterSpacing: ["1px", "3px"],
                        transform: "scale(1.05)",
                    }}
                    color="pink"
                    textAlign="center"
                    display="block"
                >
                    {children}
                </Heading>
                <Box
                    transition="all .3s ease-in"
                    _groupHover={{
                        transform: "translate(100%)",
                    }}
                    w="100%"
                    borderBlock="1px"
                />
            </Center>
        </FadeIn>
    );
}
