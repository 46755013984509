import {
    Box,
    Heading,
    Center,
    useBreakpointValue,
    SimpleGrid,
} from "@chakra-ui/react";
import FadeIn from "../Animation/FadeIn";

export default function IF_Pricing({ priceList, children }) {
    const breakpoints = useBreakpointValue({
        base: 0,
        md: 0.5,
    });

    return (
        <Center px={{ base: 4, xl: 0 }} w="100%">
            <Center w={{ base: "90%", xl: "70%" }} flexDir="column">
                {children}
                <SimpleGrid
                    gap={{ base: 4, xl: 10 }}
                    columns={{
                        base: 1,
                        md: 3,
                    }}
                    py={[4, 10]}
                >
                    {priceList.map((price, index) => (
                        <FadeIn key={index} delay={index * breakpoints}>
                            <Box
                                w="100%"
                                h="100%"
                                pos="absolute"
                                bgColor="whiteAlpha.800"
                                rounded="xl"
                            ></Box>
                            <Center
                                _hover={{
                                    transform: "translate(-5px, -5px)",
                                }}
                                transition="all 0.2s"
                                h="100%"
                                pos="relative"
                                bgColor="pink"
                                borderRadius="xl"
                            >
                                <Box textAlign="center" my={10} mx={12}>
                                    {price.title.map((title, index) => {
                                        return (
                                            <Heading
                                                key={index}
                                                fontSize="2xl"
                                                display="inline"
                                            >
                                                {title}
                                                <br />
                                            </Heading>
                                        );
                                    })}
                                </Box>
                            </Center>
                        </FadeIn>
                    ))}
                </SimpleGrid>
            </Center>
        </Center>
    );
}
