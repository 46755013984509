import { Box, Center } from "@chakra-ui/react";
import { motion } from "framer-motion";
import FadeIn from "../Animation/FadeIn";

export default function IF_Banner({
    children,
    flexDir = { base: "column-reverse", lg: "row" },
    src = "/images/kinan.png",
}) {
    return (
        <Center
            m="0px !important"
            overflow="hidden"
            pt={{ base: "12vh", lg: "20vh" }}
            px={{ base: 8, md: 0 }}
            w="100%"
            alignItems="flex-end"
            pos="relative"
        >
            <Box pos="absolute" h="100%" w="100%">
                <motion.div
                    style={{
                        height: "100%",
                        width: "100%",
                    }}
                    animate={{
                        opacity: [0, 0.5],
                        scale: [1.5, 1],
                    }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                >
                    <Box
                        h="100%"
                        w="100%"
                        bg="url('/images/bg.jpg') no-repeat bottom"
                        bgSize="cover"
                        zIndex={0}
                    />
                </motion.div>
            </Box>
            <Center gap={[0, 8]} flexDir={flexDir}>
                <Box>
                    <FadeIn amount={0}>
                        <Box
                            filter="grayScale(30%)"
                            w={{ base: "80%", md: "30vw", lg: "20vw" }}
                        >
                            <img alt="" src={src} />
                        </Box>
                    </FadeIn>
                </Box>
                <Center
                    pb={4}
                    h="100%"
                    flexDir="column"
                    w={{ base: "100%", md: "60%", "2xl": "50%" }}
                    pos={children ? "relative" : ["relative", "absolute"]}
                >
                    {children}
                </Center>
            </Center>
        </Center>
    );
}
