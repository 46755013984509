import { Heading, Stack } from "@chakra-ui/react";

export default function IF_SubMenu({ textAlign = "left", text1, text2 = "" }) {
    return (
        <Stack
            gap={0}
            mb={4}
            style={
                textAlign === "left"
                    ? { paddingLeft: ".5rem", borderLeftWidth: ".2rem" }
                    : { paddingRight: ".5rem", borderRightWidth: ".2rem" }
            }
            textAlign={textAlign}
        >
            <Heading fontSize="2xl">{text1.toUpperCase()}</Heading>
            <Heading m="0px !important" color="pink" fontSize="2xl">
                {text2.toUpperCase()}
            </Heading>
        </Stack>
    );
}
