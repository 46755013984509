import {
    Box,
    Stack,
    SimpleGrid,
    Flex,
    Circle,
    Heading,
    Center,
    Text,
    HStack,
    Avatar,
    Icon,
} from "@chakra-ui/react";
import { HiLocationMarker } from "react-icons/hi";
import { Link } from "react-router-dom";

const ListHeader = ({ children }) => {
    return (
        <Heading color="pink" fontSize={"2xl"} mb={2}>
            {children}
        </Heading>
    );
};

function Location_local({ text }) {
    return (
        <Flex>
            <Icon pe={2} fontSize="4xl" color="pink" as={HiLocationMarker} />
            <Text fontSize="xl">{text}</Text>
        </Flex>
    );
}

export default function Footer({ social_media }) {
    return (
        <Box pt={8} mt="0px !important" bgColor="rgba(255,255,255,.1)">
            <Center>
                <SimpleGrid
                    w={["90%", "80%"]}
                    columns={{ base: 1, md: 2 }}
                    gap={10}
                >
                    <Stack spacing={6}>
                        <Heading color="pink" fontSize={"3xl"}>
                            PT. Duta Bugar Indonesia
                        </Heading>
                        <Link to="/contact">
                            <Stack>
                                <Heading color="pink" fontSize={"2xl"}>
                                    Address
                                </Heading>
                                <Location_local
                                    text="Jalan A. M. Sangaji No. 9 m-n, Gambir, Petojo
                                            Utara DKI Jakarta, 10130, ID"
                                />
                                <Location_local
                                    text="Ruko Sentra Menteng Blok MN No. 37, Jl. Moh
                                        Husni Thamrin, Pd Aren Bintaro Kota Tanggerang
                                        Selattan, Banten 15220"
                                />
                            </Stack>
                        </Link>
                        <Stack>
                            <Stack>
                                <Text fontSize="xl">Founded by</Text>
                                <Heading fontSize="2xl" color="biruMuda">
                                    R. RETNA KINANTI DEWI .M.Pd.AIFO.FIT
                                </Heading>
                            </Stack>
                            <Heading fontSize="2xl">
                                © 2022 PT. Duta Bugar Indonesia
                            </Heading>
                        </Stack>
                    </Stack>
                    <Stack align={"flex-start"}>
                        <ListHeader>Social Media</ListHeader>
                        {social_media.map((contact) => (
                            <a
                                target="_blank"
                                key={contact.link}
                                href={contact.link}
                            >
                                <HStack>
                                    <Avatar p={2} src={contact.icon} />
                                    <Text fontSize="xl">{contact.text}</Text>
                                </HStack>
                            </a>
                        ))}
                    </Stack>
                </SimpleGrid>
            </Center>
            <Box pb={4}>
                <Flex
                    align={"center"}
                    _before={{
                        content: '""',
                        borderBottom: "3px solid",
                        borderColor: "pink",
                        flexGrow: 1,
                        mr: 4,
                    }}
                    _after={{
                        content: '""',
                        borderBottom: "3px solid",
                        borderColor: "pink",
                        flexGrow: 1,
                        ml: 4,
                    }}
                >
                    <Circle bgColor="white" p={2} w={20}>
                        <img alt="" src="/logo.png" />
                    </Circle>
                </Flex>
            </Box>
        </Box>
    );
}
