import IF_SubMenu from "../components/IF_SubMenu";
import IF_Banner from "../components/IF_Template/IF_banner";
import { Box, Text, VStack } from "@chakra-ui/react";
import priceList from "../data/price_list";
import { motion } from "framer-motion";
import IF_Pricing from "../components/IF_Template/IF_Pricing";
import IF_Swiper from "../components/IF_Swiper";
import IF_Heading from "../components/IF_Heading";
import FadeIn from "../components/Animation/FadeIn";
import { getTrainer } from "../api/trainer";
import { useEffect, useState } from "react";

export default function Trainer() {
    const [trainerFile, set_trainerFile] = useState([]);
    useEffect(() => {
        getTrainer().then((res) => {
            set_trainerFile(res);
        });
    }, []);
    return (
        <>
            <IF_Banner
                src="/aset/5.png"
                h={{ base: "auto", md: "65vh", lg: "70vh", xl: "100vh" }}
            >
                <motion.div
                    animate={{
                        opacity: [0, 0.5, 1],
                        x: ["100%", "-20%", "0%"],
                    }}
                    transition={{ duration: 0.5, delay: 1 }}
                >
                    <IF_SubMenu text1="personal training" text2="program" />
                    <Text fontSize="xl">
                        Program personal training (PT) adalah program yang
                        dilakukan secara private atau One on One dengan personal
                        trainer yang memiliki program secara spesifik mengenai
                        weight management sesuai dengan target yang akan
                        dicapai.
                        <br />
                        Personal trainer akan membantu, membimbing dan
                        mengarahkan client secara aman, efektif dan efesien
                        sesuai dengan body goals client. Pada program ini,
                        personal trainer akan mengukur body komposisi dan
                        lingkar badan client secara terukur dan rutin. Sehingga,
                        target yang akan tercapai dengan tepat.
                    </Text>
                </motion.div>
            </IF_Banner>
            <Box mt={8} />
            <Box>
                <IF_Heading>PERSONAL TRAINING PROGRAM</IF_Heading>
                <IF_Pricing
                    gradientColor="pink"
                    priceList={priceList.personal_trainer}
                >
                    <VStack gap={2} textAlign="center">
                        <FadeIn>
                            <Text fontSize="xl">
                                Personal training program merupakan program
                                untuk pelatihan kebugaran yang dilakukan secara
                                private.
                                <br />
                                Personal trainer akan membimbing dan memberi
                                pelatihan sesuai program yang sudah ditentukan
                                untuk mencapai keberhasilan program client.
                                <br />
                                Kami menyediakan beberapa sesi latihan sebagai
                                berikut:
                            </Text>
                        </FadeIn>
                    </VStack>
                </IF_Pricing>
            </Box>

            <IF_Swiper data={trainerFile} />
        </>
    );
}
